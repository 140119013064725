
import { createStore } from "vuex";
// import PineconeToken from "../contracts/PineconeToken";
// import Rewards from "../contracts/Rewards";
// import ProMine from "../contracts/ProMine";

import { usePinecone  } from '../contracts/MyPinecone';
import { useRewards } from '../contracts/MyRewards';

// const pineconeToken = new PineconeToken();
// const rewards = new Rewards();
// const proMine = new ProMine();

import { useWeb3 } from '../composables/useWeb3';
const { state } = useWeb3();

export default createStore({
    state: {
        // symbol: "ETH",
        // chainId: 80001,
        // Account: "0x0000000000000000000000000000000000000000",
        PineconeToken: null,
        Rewards: null,
        ProMine: null,
        referralCode: ""
    },
    mutations: {
        setPineconeToken(state, payload) {
            state.PineconeToken = payload;
        },
        setRewards(state, payload) {
            state.Rewards = payload;
        },
        setProMine(state, payload) {
            state.ProMine = payload;
        },
        setAccount(state, payload) {
            state.Account = payload;
        },
        setSymbol(state, symbol) {
            state.symbol = symbol;
        },
        setReferralCode(state, payload) {
            state.referralCode = payload;
        },
        // setChainId(state,payload){
        //     state.chainId = payload;
        // }
    },
    actions: {
        // async getSymbol({ commit }) {
        //     // const symbol = await pineconeToken.getChainCurrency();
        //     const { coin } = useWeb3();
        //     const symbol = await coin.value;
        //     console.log('coin',symbol);
        //     commit('setSymbol', symbol); // 调用 setSymbol mutation 设置状态
        // },
        // async getAccount({ commit }) {
            
        //     // const accountAddress = await pineconeToken.requestAccount();
        //     const { account } = useWeb3();
        //     const  accountAddress= account.value;
        //     console.log('accountAddress',accountAddress)
        //     commit('setAccount', accountAddress);
        // },
        // async setReferralCode({commit},referralCode){
        //     await pineconeToken.initContract();
        //     const result = await pineconeToken.readContract('addressState',[referralCode]);
        //     console.log('setReferralCode result:',result);
        //     if(result>=2){
        //         localStorage.setItem("referralCode", referralCode);
        //         commit('setReferralCode', result);
        //     }
        // },
        // async getReferralCode({commit}){
        //     const referralCode = localStorage.getItem("referralCode");
        //     console.log('referralCode get:',referralCode);
        //     //当这里不正确时，就自己读取地址
        //     commit('setReferralCode', referralCode);
        // },
        async getPineconeToken({ commit }) {
            
            console.log('state',state.account);

            const sender = state.account;
            console.log('getPineconeToken-accountAddress',sender);

            // await pineconeToken.initContract();
            // const tokenInfo = await pineconeToken.callTargetFunctions(state.Account,['getMigrationCount']);
            // const incomes = await pineconeToken.readContract('getReferralIncomes',[state.Account],state.Account);
            // tokenInfo.getReferralIncomes = incomes;
            // console.log('getPineconeToken-newValue',tokenInfo);

            const { getTokenInfo } = usePinecone();
            const tokenInfo = await getTokenInfo(sender,['getMigrationCount','name','symbol']);
            console.log('getPineconeToken-newValue',tokenInfo);

            commit('setPineconeToken', tokenInfo);
        },
        async getRewards({ commit }) {
            // console.log('accountAddress',state.Account)
            // const newValue = await rewards.callTargetFunctions(state.Account);
            // console.log('getRewards-newValue',newValue);

            const sender = state.account;
            console.log('getRewards-sender',sender);
            const { getTokenInfo } = useRewards();
            const tokenInfo = await getTokenInfo(sender,[]);
            console.log('getRewards-newValue',tokenInfo);

            commit('setRewards', tokenInfo);
        },
        // async getProMine({ commit, state }) {
        //     console.log('accountAddress',state.Account)
        //     const newValue = await proMine.callTargetFunctions(state.Account);
        //     console.log('getProMine-newValue',newValue);
        //     commit('setProMine', newValue);
        // },
        async fetchAllData({ dispatch }) {
            console.log('fetchAllData');
            // await dispatch('getSymbol');
            // await dispatch('getAccount');
            // await dispatch('getReferralCode');
            await dispatch('getPineconeToken');
            // await dispatch('getRewards');
            // await dispatch('getProMine');
        },
    },
    getters: {
        // 在这里定义你的 getters
    },
    modules: {
        // 如果需要，可以在这里定义子模块
    },
});
