
<template>
  <div class="fullscreen-container">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'FullScreenContainer'
}
</script>
  
<style scoped>
.fullscreen-container {
  position: relative;
  width: 100vw;
  height: 90vh;
}

.content {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom:50px;
}

</style>
  