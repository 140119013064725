import { computed } from 'vue';
import { useMulticall } from './MyMulticall';
const { multiCallForSingle } = useMulticall();
import contractAddresses from "./contractAddress.json";

export function createContract(useWeb3, addressName, contractABI) {
    const { web3 ,state } = useWeb3();

    const currentContractAddresses = computed(() => {
        const chainId = state.chainId;//await web3.value.eth.getChainId();
        // console.log('currentContractAddresses-chainId', chainId);
        // const address = contractAddresses[chainId][addressName];
        // console.log('currentContractAddresses-address', address);
        return contractAddresses[chainId][addressName];
    });

    const contract = computed( () => {
        const currentAddress = currentContractAddresses.value;
        if (web3.value && currentAddress) {
            let from;
            if (web3.value.currentProvider.selectedAddress) {
                from = web3.value.currentProvider.selectedAddress;
            } else if (web3.value.eth.accounts.wallet.length > 0) {
                from = web3.value.eth.accounts.wallet[0].address;
            } else {
                from = null; // 如果没有连接钱包，from 为 null
            }
            // console.log('createContract currentContractAddresses.value', currentAddress)
            // console.log('createContract contractABI', contractABI)
            return new web3.value.eth.Contract(contractABI, currentAddress, {
                from: from
            });
        }
        return null;
    });


    function findFunctions(abi, filter) {
        const targetFunctions = [];

        abi.forEach((item) => {
            if (item.type !== 'function' || filter.includes(item.name)) return;
            const inputCount = item.inputs.length;
            if (item.stateMutability == 'view') {
                if (inputCount === 0) {
                    targetFunctions.push(item);
                } else if (inputCount === 1 && item.inputs[0].type === 'address') {
                    targetFunctions.push(item);
                }
            }
        });

        return targetFunctions;
    }

    async function callTargetFunctions(sender = '', filter = []) {
        const targetFunctions = findFunctions(contractABI, filter);
        const functionSelectors = [];
        const functionOutputs = [];

        targetFunctions.forEach((func) => {
            const inputCount = func.inputs.length;

            // 计算函数选择器
            let functionSelector = func.signature;
            if (!functionSelector) {
                const signature = `${func.name}(${func.inputs.map(input => input.type).join(",")})`;
                functionSelector = web3.value.eth.abi.encodeFunctionSignature(signature);
            }

            if (inputCount === 0) {
                functionSelectors.push(functionSelector);
            } else if (inputCount === 1 && func.inputs[0].type === "address") {
                const data = web3.value.eth.abi.encodeParameter("address", sender);
                functionSelectors.push(functionSelector + data.slice(2));
            }

            functionOutputs.push(func.outputs);
        });

        const currentAddress = await currentContractAddresses.value;

        try {



            console.log('createContract current chainId', await web3.value.eth.getChainId());
            console.log('callTargetFunctions currentContractAddresses.value', currentAddress)
            const result = await multiCallForSingle(currentAddress, functionSelectors);
            // console.log('callTargetFunctions result:',result);

            const info = parseMultiCallResult(
                result,
                functionOutputs,
                targetFunctions.map((func) => func.name)
            );
            return info;

        } catch (error) {
            console.error(
                `Error in callTargetFunctions by ${currentAddress}`,
                error
            );
            return null;
        }
    }

    function parseMultiCallResult(result, functionOutputs, functionNames) {
        const info = {};

        // console.log('functionNames',functionNames)
        result.forEach((res, index) => {
            const output = functionOutputs[index];
            const outputLength = output.length;

            if (outputLength == 1) {
                const [outputItem] = output;

                if (outputItem.type === "tuple[]") {
                    console.log('type');
                    const tupleValues = web3.value.eth.abi.decodeParameters(output, res);
                    info[functionNames[index]] = tupleValues;
                } else {
                    info[functionNames[index]] = web3.value.eth.abi.decodeParameter(outputItem.type, res);
                }
            } else {
                const outputValues = web3.value.eth.abi.decodeParameters(output, res);
                info[functionNames[index]] = outputValues;
            }

        });

        return info;
    }


    // function writeContract(myMethod, sender, inputs, ethAmount = 0) {
    //     return new Promise((resolve, reject) => {
    //         const contractInstance = contract.value;

    //         contractInstance.methods[myMethod](...inputs).send({
    //             from: sender,
    //             value: ethAmount
    //         })
    //             .on('transactionHash', (transactionHash) => {
    //                 console.log(`Transaction hash is ${transactionHash}`);
    //                 resolve(transactionHash);
    //             })
    //             .on('receipt', (receipt) => {
    //                 console.log('Transaction receipt:', receipt);
    //                 resolve(receipt);
    //             })
    //             .on('error', (error) => {
    //                 console.log(`Transaction error: ${error.message}`);
    //                 reject(error);
    //             });
    //     });
    // }


    function writeContract(method, sender, inputs, ethAmount = 0) {
        const contractInstance = contract.value;

        return contractInstance.methods[method](...inputs).send({
            from: sender,
            value: ethAmount
        });
    }

    function readContract(method, sender, inputs) {
        let methods = contract.value.methods;
        return methods[method](...inputs).call({
            from: sender
        });
    }

    return {
        contract,
        callTargetFunctions,
        writeContract,
        readContract
    };
}