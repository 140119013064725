import { computed, watch, reactive,toRef } from 'vue'; //toRef
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

// 默认网络 ID
const DEFAULT_NETWORK_ID = 137; // 主网

const CHAINS = {
    1: {
        name: 'Ethereum',
        coin: 'ETH',
        rpcUrl: 'https://mainnet.infura.io/v3/ca9724e61b014bb9a6852b70deead7a7'
    },
    137: {
        name: 'Polygon',
        coin: 'MATIC',
        rpcUrl: 'https://polygon-mainnet.infura.io/v3/ca9724e61b014bb9a6852b70deead7a7'
    }
};


const web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true, // optional
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: 'YOUR_INFURA_KEY',
                supportedChainIds: Object.keys(CHAINS) // Add supported networks to list
            }
        }
    }
});

const state = reactive({
    chainId: DEFAULT_NETWORK_ID,
    account: "0x0000000000000000000000000000000000000000",
    web3: null
});



export function useWeb3() {
    console.log('useWeb3()...')

    const coin = computed(() => CHAINS[state.chainId].coin);
    const rpcUrl = computed(() => CHAINS[state.chainId].rpcUrl);

    if (!state.web3) {
        console.log('update new Web3()')
        state.web3 = new Web3(rpcUrl.value);
    }

    const web3 = computed(() => state.web3);

    watch(
        toRef(state, "web3"),
        async (newValue,oldValue) => {
            const oldChainId = oldValue ? await oldValue.eth.getChainId() : 0;
            const newChainId = await newValue.eth.getChainId();
            if (oldChainId != newChainId && newChainId !== state.chainId) {
                state.chainId = newChainId;
            }

            const accounts = await newValue.eth.getAccounts();
            if (accounts.length > 0 && accounts[0] !== state.account) {
                state.account = accounts[0];
            }

            console.log('chainId',state.chainId);
            console.log('account',state.account);

        },
        { immediate: true }
    );

    async function connectWallet() {
        const modalProvider = await web3Modal.connect();
        state.web3 = new Web3(modalProvider);

        // store the wallet address and network ID in localStorage
        if (state.web3.currentProvider && state.web3.currentProvider.selectedAddress) {

            const newChainId = await state.web3.eth.net.getId();
            console.log('connectWallet-newChainId',newChainId);
            state.chainId = newChainId;
            state.account = state.web3.currentProvider.selectedAddress;
            console.log('connectWallet-state.account',state.account);

            localStorage.setItem("walletAddress", state.account);
            localStorage.setItem("networkId", newChainId);
        } else {
            localStorage.removeItem("walletAddress");
            localStorage.removeItem("networkId");
        }
    }


    async function autoConnectWallet() {
        const savedAddress = localStorage.getItem("walletAddress");
        const savedNetworkId = localStorage.getItem("networkId");
        if (!savedAddress || !savedNetworkId) {
            return;
        }

        try {
            web3Modal.setProviderFromOptions({
                network: CHAINS[savedNetworkId].name,
                cacheProvider: false,
            })
            const modalProvider = await web3Modal.connect();
            const web3Instance = new Web3(modalProvider);
            const accounts = await web3Instance.eth.getAccounts();
            const selectedAddress = accounts[0];
            if (selectedAddress === savedAddress) {
                state.web3 = web3Instance;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function disconnectWallet() {
        if (web3Modal.cachedProvider) {
            console.log("disconnectWallet");
            await web3Modal.clearCachedProvider();
            state.web3.value = new Web3(state.rpcUrl);
        }
    }

    function updateRpcUrl(newUrl) {
        console.log('rpcUrl',rpcUrl);
        state.rpcUrl = newUrl;
        state.web3.value = new Web3(state.rpcUrl);
    }

    function updateAccount(newAccount) {
        state.account = newAccount;
    }

    async function updateChainId(newChainId) {
        state.chainId = newChainId;
        // await connectWallet();
    }

    async function getBalance() {
        let address = await state.web3.eth.getCoinbase();
        let balance = await state.web3.eth.getBalance(address);
        balance = state.web3.utils.fromWei(balance, 'ether');
        return balance;
        
    }
   



    return {
        web3,
        coin,
        state,
        connectWallet,
        disconnectWallet,
        updateRpcUrl,
        autoConnectWallet,
        updateAccount,
        updateChainId,
        getBalance
    };
}
