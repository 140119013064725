<template>
    <div class="common-layout">
        <el-container style="width:100vw;height:100vh;">
            <el-header fixed style="flex:1;" class="flex align-items-center justify-content-center">
                <TopMenu :toggleVisible="!isNavbarToggleVisible" @menuClick="showOffcanvas" />
            </el-header>
            <el-scrollbar>
                <PopupMenu :visibleCanvas="visible" @updateVisible="updateVisible" />
                <el-main class="flex justify-content-center">
                    <slot></slot>
                </el-main>
                
                <Footer />
            </el-scrollbar>
        </el-container>
    </div>
</template>

<script>

import { defineComponent, ref } from 'vue'
import TopMenu from '@/components/TopMenu.vue'
import PopupMenu from '@/components/PopupMenu.vue'
import Footer from '@/components/Footer.vue';

export default defineComponent({
    name: 'EarnPage',
    components: {
        TopMenu,
        PopupMenu,
        Footer
    },
    setup() {
        const visible = ref(false);
        const isNavbarToggleVisible = ref(false);
        function showOffcanvas() {
            visible.value = true;
        }
        function updateVisible(e){
            visible.value=e;
        }

        return {
            visible,
            isNavbarToggleVisible,
            showOffcanvas,
            updateVisible
        }
    },

})
</script>
