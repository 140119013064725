<template>
    <el-footer class="w-full footer-bg" style="height:300px; padding:0 40px; 0 40px;">

        <el-row :gutter="10">
            <el-col :xs="0" :sm="2" :md="2" :lg="2" :xl="2"></el-col>
            <el-col :xs="16" :sm="10" :md="10" :lg="10" :xl="10" class="justify-content-start">
                <div class="flex flex-column align-content-center justify-content-center" style="height:300px;width:200px;">
                    <div class="flex flex-row align-content-center justify-content-center">
                        <img src="/assets/logo.png" alt="PineconeDAO Logo"
                            style="width:50px; height:50px; margin-right: 5px;" />
                        <p-text class="pine-color-grey font-bold" style="line-height:50px;">PineconeDAO</p-text>
                    </div>

                    <el-text class="text-xs text-600" style="margin-top:10px;font-size:18px;font-weight:600;">©2023
                        Copyright
                        PineconeDAO.</el-text>
                </div>
            </el-col>
            <el-col :xs="8" :sm="10" :md="10" :lg="10" :xl="10" class="flex justify-content-end">

                <div class="flex flex-column align-items-start justify-content-center" style="height:300px;width:100px;">
                    <span style="font-size: 18px; font-weight: 600;">{{ $t('community') }}</span>
                    <el-link href="https://discord.gg/your-discord-link" target="_blank" :underline="false">
                        <span style="font-size: 16px; font-weight: 600;margin-top:10px;">Discord</span>
                    </el-link>
                    <el-link href="https://twitter.com/PineconeDAO" target="_blank" :underline="false">
                        <span style="font-size: 16px; font-weight: 600;">Twitter</span>
                    </el-link>
                    <el-link href="https://www.facebook.com/profile.php?id=100093263508973" target="_blank"
                        :underline="false">
                        <span style="font-size: 16px; font-weight: 600;">Facebook</span>
                    </el-link>
                    <el-link href="https://t.me/pineconedao" target="_blank"
                        :underline="false">
                        <span style="font-size: 16px; font-weight: 600;">Telegram</span>
                    </el-link>
                </div>

            </el-col>
            <el-col :xs="0" :sm="2" :md="2" :lg="2" :xl="2" ></el-col>
        </el-row>



    </el-footer>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FooterName',
    setup() {
        
    },
})
</script>

<style scoped>

.footer-bg {
    background-color: #00002e;
    /* background-image: url('@/assets/img/footer-bg.png');
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: cover; */

    border-top: 4px solid #262654;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

</style>