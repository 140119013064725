import { createContract } from './createContract';
import { useWeb3 } from '@/composables/useWeb3';

import Contract_ABI from "./abis/PineconeToken.json";
// import contractAddresses from "./contractAddress.json";

export function usePinecone() {
    const baseContract = createContract(useWeb3, 'pineconeTokenAddress', Contract_ABI.abi);
    const { contract, callTargetFunctions,writeContract,readContract } = baseContract;

    async function getTokenInfo(...args) {
        const result = await callTargetFunctions(...args);
        return result;
    }

    return {
        contract,
        getTokenInfo,
        writeContract,
        readContract
    };
}
