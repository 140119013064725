<template>
        <b-navbar toggleable="lg" type="dark" :class="toggleVisible ? 'w-11' : 'w-full'">
            <b-navbar-brand href="/" style="font-size:16px;">
                <img src="../assets/logo.png" class="d-inline-block align-center" style="width:36px;height:36px;">
                <p-text class="pine-color-grey font-bold">PineconeDAO</p-text>
            </b-navbar-brand>
            <b-navbar-toggle class="pine-navbar-toggle" @click="showOffcanvas"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>

                <b-navbar-nav class="ml-auto d-flex align-items-center">
                    <b-nav-item href="/" class="pine-dropdown">
                        {{ $t('Home') }}
                    </b-nav-item>
                    <b-nav-item
                        href="https://docs.pineconedao.com/v/zh-cn/12.-yong-hu-zhi-nan/song-guo-dao-xin-shou-zhi-nan-kai-qi-nin-de-zhi-hui-tou-zi-yu-cai-fu-zeng-chang-zhi-lv"
                        class="pine-dropdown">{{ $t('guidance') }}</b-nav-item>
                    <b-nav-item href="https://list.pineconedao.com" class="pine-dropdown">{{ $t('Project')
                    }}</b-nav-item>
                    <b-nav-item :href="$t('faq-url')" class="pine-dropdown">{{ $t('faq') }}</b-nav-item>
                    <b-nav-item :href="$t('whitePaper-url')" class="pine-dropdown">{{ $t('whitePaper')
                    }}</b-nav-item>

                    <b-nav-item
                        href="https://quickswap.exchange/#/swap?currency0=0xE1b3d3f9302FB84C8d5B9e13cd8160fEE67Ea239&currency1=ETH&swapIndex=0&isProMode=true"
                        class="pine-dropdown" target="_blank">
                        {{ $t('Trade') }}
                    </b-nav-item>

                    <b-nav-item class="pine-dropdown" href="/earn">
                        {{ $t('Earn') }}
                    </b-nav-item>
                    
                    <b-nav-item class="pine-dropdown" href="https://snapshot.org/#/pineconedao.eth">
                        {{ $t('Voting') }}
                    </b-nav-item>

                    <b-nav-item-dropdown :text="$t('language')" right class="pine-dropdown">
                        <b-dropdown-item @click="changeLanguage('en')">{{ $t('English') }}</b-dropdown-item>
                        <b-dropdown-item @click="changeLanguage('zhCN')">{{ $t('Simplified Chinese')
                        }}</b-dropdown-item>
                        <b-dropdown-item @click="changeLanguage('zhTW')">{{ $t('Traditional Chinese')
                        }}</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-form>
                        <el-button color="#ffae01" size="large" round v-show="toggleVisible"
                            @click="openApp">{{
                                $t('Launch App') }}</el-button>
                    </b-nav-form>

                </b-navbar-nav>

            </b-collapse>

        </b-navbar>

</template>
  
<script>
// import { ref } from 'vue'
import { useI18n } from 'vue-i18n';

export default {
    props: {
        toggleVisible: {
            type: Boolean,
            required: true
        }
    },
    emits: ['menuClick'],
    setup(props, { emit }) {

        function showOffcanvas() {
            emit('menuClick');
        }

        const { locale } = useI18n();
        const changeLanguage = (newLocale) => {
            locale.value = newLocale;
            localStorage.setItem('locale', newLocale);
        }

        const openApp = () => {
            window.open('https://app.pineconedao.com', '_blank');
        };

        return {
            changeLanguage,
            showOffcanvas,
            openApp

        }
    }
}
</script>
  