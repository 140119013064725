<template>
  <metainfo></metainfo>
  <!-- 这个组件将根据当前路由渲染相应的页面组件 -->
  <router-view></router-view>
</template>

<script>
// import { mapActions } from "vuex";
// import { provide } from "vue";
// import store from "./store";

import { useMeta } from 'vue-meta';
import { useStore } from 'vuex';

import { defineComponent, onMounted } from "vue";
import { useWeb3 } from './composables/useWeb3';
const {updateAccount,updateChainId} = useWeb3();

export default defineComponent({
  name: "App",
  setup() {
    // 获取组件的 Store 实例对象
    const store = useStore();

    // 使用 onMounted 函数监听组件的 mounted 事件
    onMounted(async () => {
      useMeta({
        title: 'PineconeDAO'
      });

      // 监听钱包地址和链的变化
      if (window.ethereum) {
        window.ethereum.on(
          "accountsChanged",
          handleAccountChange.bind(this)
        );
        window.ethereum.on("chainChanged", handleChainChange.bind(this));
      }

      // 调用 Vuex 的 fetchAllData 动作
      await store.dispatch("fetchAllData");

    });



    // 定义 handleAccountChange 函数来处理账号变化
    const handleAccountChange = async (accounts) => {
      console.log("accounts", accounts);
      if (accounts.length > 0) {
        // 定义一个新的变量来存储账号
        const newAccount = accounts[0];
        updateAccount(newAccount);
        // store.commit('setAccount',newAccount);

        // 调用 fetchAllData 动作
        await store.dispatch("fetchAllData");


      }
    };

    // 定义 handleChainChange 函数来处理链的变化
    const handleChainChange = (newChainId) => {
      const chainIdInt = parseInt(newChainId, 16);
      console.log("new chainId", chainIdInt);
      updateChainId(chainIdInt);
      // 设置新的链 ID 值
      // web3State.data.chainId = newChainId;
    };

    // 通过 computed 函数获取 account 和 chainId 的值
    // const currentAccount = computed(() => account.value);
    // const currentChainId = computed(() => chainId.value);

    // 返回组件的选项
    return {
      // currentAccount,
      // currentChainId,
      // ...mapActions(["fetchAllData"]),
    };
  },
});

// export default {
//   name: "App",
//   async created() {
//     // 监听钱包地址和链的变化
//     if (window.ethereum) {
//       window.ethereum.on("accountsChanged", this.handleAccountChange.bind(this));
//       window.ethereum.on("chainChanged", this.handleChainChange.bind(this));
//     }

//     const store = useStore();
//     await store.dispatch('fetchAllData');

//   },
//   mounted() {
//     useMeta({
//       title: 'PineconeDAO'
//     });
//   },
//   methods: {
//     ...mapActions(["fetchAllData"]),
//     async handleAccountChange(accounts) {
//       console.log("accounts", accounts);
//       const store = useStore();
//       const {account} = useWeb3();
//       console.log('store',store)
//       if (accounts.length > 0) {
//         // store.commit("setAccount", accounts[0]);
//         account.value= accounts[0];
//         await store.dispatch("fetchAllData");
//       }
//     },

//     handleChainChange(newChainId) {
//       const {chainId} = useWeb3();
//       console.log('new chainId',newChainId);
//       chainId.value = newChainId;
//     },
//   },
// };
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
} */




body {
  margin: 0;
  width: 100%;
  min-width: 320px;
  min-height: 100vh;
  color: var(--color-text);
  background: var(--color-background);
  transition: color 0.5s, background-color 0.5s;
  line-height: 1.6;

  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  /* font-family: 'Open Sans', sans-serif; */
    font-weight: 400;
    background-color: #03091F;
    /* -webkit-font-smoothing: antialiased; */

}

.pine-color-yellow1 {
  color: #fce8b5;
}

.pine-color-yellow2 {
  color: #ffefd0;
}

.pine-color-red {
  color: #ff0000;
}
</style>
