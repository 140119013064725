<template>
        <el-main class="flex justify-content-center" style="transform: translateY(50px); padding: 100px 0; background-color: #00002e; overflow-x: hidden;">

        <el-row :gutter="20" class="pine-main">
            <el-col :span="24" :sm="12" :md="6" :lg="6" :xl="6" style="margin-bottom:20px;"
                class="flex justify-content-center flex flex-column">
                <el-text class="text-6xl text-color">${{ formatMillions(estimatedAmount) }}+</el-text>
                <el-text class="text-1xl">{{ $t('totalAmount') }}</el-text>
            </el-col>

            <el-col :span="24" :sm="12" :md="6" :lg="6" :xl="6" style="margin-bottom:20px;"
                class="flex justify-content-center flex flex-column">
                <el-text class="text-6xl text-color">${{ decimalPlaces(totalReferralAmount) }}</el-text>
                <el-text class="text-1xl">{{ $t('invitationRewards') }}</el-text>
            </el-col>

            <el-col :span="24" :sm="12" :md="6" :lg="6" :xl="6" style="margin-bottom:20px;"
                class="flex justify-content-center flex flex-column">
                <el-text class="text-6xl text-color">{{ formatMillions(totalIssuedPines) }}</el-text>
                <el-text class="text-1xl">{{ $t('maxPinesSupply') }}</el-text>
            </el-col>

            <el-col :span="24" :sm="12" :md="6" :lg="6" :xl="6" style="margin-bottom:20px;"
                class="flex justify-content-center flex flex-column">
                <el-text class="text-6xl text-color">{{ referralActivationsTotal }}</el-text>
                <el-text class="text-1xl">{{ $t('activatedInvitations') }}</el-text>
            </el-col>

           
        </el-row>
    </el-main>
</template>
  
<script>
import { computed,ref,watch } from 'vue';
import { useStore } from 'vuex';
import BigNumber from 'bignumber.js';
import {formatMillions,decimalPlaces} from '@/common/utils.js';

export default {




    setup() {
        // 获取 Vuex store
        const store = useStore();
        const estimatedAmount = ref('');
        const totalIssuedPines = ref('');
        const totalReferralAmount = ref('');
        const referralActivationsTotal = ref('');

        // 从 store 中映射状态
        // const Account = computed(() => store.state.Account);
        // const PineconeToken = computed(() => store.state.PineconeToken);
        // const ProMine = computed(() => store.state.ProMine);
        // const Rewards = computed(() => store.state.Rewards);
        const symbol = computed(() => store.state.symbol);

        
        watch(
        () => store.state.PineconeToken,
        (newValue) => {
            if (newValue) {
            const upgradeFeeUSDT = new BigNumber(newValue.upgradeFeeUSDT);
            const MAX_ACTIVATION_LIMIT = newValue.MAX_ACTIVATION_LIMIT;
            const divisor = new BigNumber(Math.pow(10, 18));

            const coinPrice = new BigNumber(newValue.getPriceETH).div(Math.pow(10,8));

            estimatedAmount.value = upgradeFeeUSDT.div(divisor).times(MAX_ACTIVATION_LIMIT).toString();

            totalIssuedPines.value = MAX_ACTIVATION_LIMIT;
            totalReferralAmount.value = newValue.totalReferralAmount * coinPrice;
            referralActivationsTotal.value = newValue.referralActivationsTotal;
            }
        },
        { immediate: true }
        );

        // 返回计算属性，以便在模板中使用
        return {
            estimatedAmount,
            formatMillions,
            totalIssuedPines,
            totalReferralAmount,
            referralActivationsTotal,
            decimalPlaces,
            symbol
        };
    }


};
</script>
  
<style lang="scss">
.el-text {
    text-align: center;
}

.text-color {
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, rgb(0, 209, 255) 0%, rgb(0, 209, 255) 15%, rgb(237, 30, 255) 85%, rgb(237, 30, 255) 100%);
    font-family: Impact, sans-serif;
    font-weight: 500;
}

.border-top{
    border-top: 4px solid #262654;
}
</style>
  